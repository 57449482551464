import UpdateProfileModal from '@/components/modal/UpdateProfileModal.vue'
import UpdatePasswordModal from '@/components/modal/UpdatePasswordModal.vue'
import DeleteAccountModal from '@/components/modal/DeleteAccountModal.vue'

import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'

const getUpdateProfileModalConfig = () => ({
  attrs: {
    modalId: 'update_profile',
    title: 'Mes informations',
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: UpdateProfileModal,
      attrs: {
        onOpenUpdatePasswordModal,
        onDeleteAccountModal,
      },
    }),
  },
})

const getUpdatePasswordModalConfig = () => ({
  attrs: {
    modalId: 'update_password',
    title: 'Modifier mon mot de passe',
    clickToClose: false,
    onClosed: resetUpdateProfileModal,
  },
  slots: {
    default: useModalSlot({
      component: UpdatePasswordModal,
      attrs: {
        onOpenUpdateProfileModal,
      },
    }),
  },
})

const getDeleteAccountModalConfig = () => ({
  attrs: {
    modalId: 'delete_account',
    title: 'Supprimer mon compte',
    clickToClose: false,
    onClosed: resetUpdateProfileModal,
  },
  slots: {
    default: useModalSlot({
      component: DeleteAccountModal,
    }),
  },
})

const resetUpdateProfileModal = () => {
  updateProfileModal.patchOptions(getUpdateProfileModalConfig())
}

const onDeleteAccountModal = async () => {
  updateProfileModal.patchOptions(getDeleteAccountModalConfig())
}

const onOpenUpdatePasswordModal = async () => {
  updateProfileModal.patchOptions(getUpdatePasswordModalConfig())
}

const onOpenUpdateProfileModal = async () => {
  updateProfileModal.patchOptions(getUpdateProfileModalConfig())
}

export const updateProfileModal = useCustomModal(getUpdateProfileModalConfig())
