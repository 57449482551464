<template>
  <div>
    <form @submit.prevent="submit">
      <p class="u-mb-4">
        Renseignez l'ancien mot de passe et le nouveau mot de passe souhaité
        dans les champs ci-dessous.
      </p>
      <div class="o-row">
        <div class="o-col u-12">
          <div class="c-form__group u-mb-3">
            <input
              id="username"
              type="email"
              autocomplete="username"
              hidden
            />
            <input
              id="current_password"
              v-model="formData.current_password"
              type="password"
              name="current_password"
              class="c-form__control"
              placeholder="Ancien mot de passe*"
              autocomplete="current-password"
              required
              :class="{
                'is-invalid': errorMessage || errors.current_password,
              }"
            />
            <span
              v-if="errors.current_password"
              class="c-form__feedback is-invalid"
            >
              {{ errors.current_password }}
            </span>
          </div>
        </div>
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3">
            <input
              id="password"
              v-model="formData.password"
              type="password"
              name="password"
              class="c-form__control"
              placeholder="Nouveau mot de passe*"
              required
              autocomplete="new-password"
              :class="{
                'is-invalid':
                  errorMessage ||
                  errors.password ||
                  errors.password_confirmation,
              }"
            />
          </div>
        </div>
        <div class="o-col u-6@tb">
          <div class="c-form__group u-mb-3">
            <input
              id="password_confirmation"
              v-model="formData.password_confirmation"
              type="password"
              class="c-form__control"
              name="password_confirmation"
              autocomplete="new-password"
              placeholder="Confirmer le mot de passe*"
              required
              :class="{
                'is-invalid':
                  errorMessage ||
                  errors.password ||
                  errors.password_confirmation,
              }"
            />
          </div>
        </div>
      </div>
      <div
        class="text-small u-mb-3"
        :class="{
          'is-invalid':
            errorMessage || errors.password || errors.password_confirmation,
        }"
      >
        <span v-if="errors.password">{{ errors.password }} <br /></span>
        <span v-if="errors.password_confirmation">
          {{ errors.password_confirmation }} <br />
        </span>
        <span v-if="errorMessage">{{ errorMessage }} <br /></span>
        8 caractères minimum. Au moins une majuscule et un caractère spécial.
      </div>
      <div
        v-if="successMessage"
        class="is-success text-small u-mb-3"
      >
        {{ successMessage }}
      </div>
      <div class="u-d-f u-jc-fe u-ai-c u-mt-5">
        <span
          class="link"
          @click="openProfile"
          >Retour</span
        >
        <ButtonSimple
          type="submit"
          label="Enregistrer"
        />
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import type { ValidationError } from 'yup'

const errorsYup = {
  current_password: '',
  password: '',
  password_confirmation: '',
}

const emit = defineEmits<{
  openUpdateProfileModal: []
  close: []
}>()

const errorMessage = ref('')
const successMessage = ref('')

const formData = reactive({
  current_password: '',
  password: '',
  password_confirmation: '',
})

const errors = ref<Partial<typeof errorsYup>>({})

const submit = async () => {
  let validated = false

  errorMessage.value = ''
  successMessage.value = ''

  try {
    await passwordUpdateFormSchema.validate(formData, {
      abortEarly: false,
    })
    errors.value = {
      ...errorsYup,
    }

    validated = true
  } catch (err) {
    const error = err as ValidationError

    errors.value = {}
    let path: keyof typeof errors.value

    error.inner.forEach((innerError) => {
      const innerErrorPath = innerError.path as keyof typeof errors.value
      if (!path) {
        path = innerErrorPath
      }
      if (innerError.path) {
        errors.value[innerErrorPath] = innerError.message
      }
    })
  }

  // Security check
  if (!validated) {
    return
  }

  const {
    current_password,
    password: first,
    password_confirmation: second,
  } = formData

  const { error } = await useCustomFetch(API.PUT__UPDATE_PASSWORD, {
    method: 'PUT',
    body: {
      current_password,
      new_password: {
        first,
        second,
      },
    },
  })

  if (error.value) {
    const { data } = error.value

    if (data.type === 'DIFFERENT_PASSWORD_DATABASE') {
      errorMessage.value = `L'ancien mot de passe saisie est incorrect.`
    } else if (data.type === 'IDENTICAL_PASSWORD_INPUT') {
      errorMessage.value = `L'ancien et le nouveau mot de passe sont identiques`
    }
  } else {
    successMessage.value = 'Le mot de passe a bien été mis à jour.'
  }
}

const openProfile = () => {
  emit('openUpdateProfileModal')
}
</script>

<style lang="scss" scoped>
.link {
  display: flex;
  align-items: center;
  margin-right: 3rem;
  font-size: 1.2rem;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 1rem;
    margin-right: 0.8rem;
    background-image: url('assets/icons/chevron.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: rotate(180deg);
  }
}

.text-small {
  font-size: 1.2rem;
}

.is-invalid {
  color: #ea5151;
}

.is-success {
  color: $blue;
  font-weight: normal;
}
</style>
