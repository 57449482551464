<template>
  <div class="account-header">
    <div class="content">
      <div class="first-part">
        <div class="profile">
          <div class="img-gauge-container">
            <div class="gauge-container">
              <ClientOnly>
                <div
                  v-if="
                    isMobile ||
                    isLargeMobile ||
                    isTabletPortrait ||
                    isSmallScreen
                  "
                >
                  <ve-progress
                    :progress="progressRank"
                    :size="96"
                    line="square"
                    :thickness="15"
                    empty-thickness="0"
                    color="#1ba2fc"
                    empty-color="#FFFFFF"
                  />
                </div>
                <div v-else>
                  <ve-progress
                    :progress="progressRank"
                    :size="204"
                    line="square"
                    :thickness="15"
                    empty-thickness="0"
                    color="#1ba2fc"
                    empty-color="#FFFFFF"
                  />
                </div>
              </ClientOnly>
            </div>
            <div class="img-container">
              <NuxtImg
                v-if="infoUser?.avatar"
                :src="infoUser.avatar.url"
                alt="avatar"
                sizes="sm:86px tb:186px"
                fit="cover"
                format="auto"
              />
            </div>
            <div
              v-if="infoUser?.badge_displayed?.badge?.image"
              class="badge-container"
              :class="[infoUser.badge_displayed.badge.type.slug]"
            >
              <img
                :src="infoUser.badge_displayed.badge.image.url"
                alt="badge"
              />
            </div>
          </div>
          <NuxtLink
            v-if="menuLayout"
            to="/mon-profil"
            class="button-change-badge"
            @click="
              gtag('event', 'cta', {
                cta_name: 'Accéder à mon profil',
              })
            "
            >Accéder à mon profil</NuxtLink
          >
          <div
            v-else-if="!profil"
            class="button-change-badge"
            @click="openBadgeSelector"
          >
            Changer de badge
          </div>
        </div>
        <div class="infos">
          <h3>{{ capitalize(infoUser?.nickname) }}</h3>
          <p class="level">{{ infoUser?.rank_name }}</p>
          <p class="points">
            {{ infoUser?.points_total }}
            {{ pluralize('point', infoUser?.points_total) }}
          </p>
          <div class="medals">
            <div
              v-for="medal in badgeKeys"
              :key="medal"
              class="medal"
              :class="[medal]"
            >
              <div class="medal-icon">
                <img
                  :src="`/icons/${medal}.svg`"
                  :alt="medal"
                />
              </div>
              <p class="count">{{ infoUser?.badges_total?.[medal] }}</p>
            </div>
          </div>
          <div
            v-if="profil"
            class="since"
          >
            Membre depuis le {{ dateToString(infoUser?.created_at) }}
          </div>
        </div>
      </div>
      <div class="second-part">
        <div class="hr" />
        <div
          v-if="!profil"
          class="progress-gauge"
        >
          <p class="count">
            Profil complété à {{ infoUser?.profile_completion_percent ?? 0 }}%
          </p>
          <TheGauge :percent="infoUser?.profile_completion_percent" />
        </div>
        <template v-if="!profil">
          <ButtonPoint
            v-if="(infoUser?.profile_completion_percent ?? 0) < 100"
            :points="referentialStore.referential?.points.user_profile_full"
            class="button-point"
            label="Compléter mon profil"
            @click="openProfile"
          />
          <ButtonSimple
            v-else
            label="Modifier mes informations"
            @click="openProfile"
          />
        </template>
        <div
          v-else
          class="button-container"
        >
          <div
            v-if="infoUser?.is_following"
            class="button sub"
            @click="tryUnfollow"
          >
            Suivi
            <img
              src="~/assets/icons/check.svg"
              alt="check"
              class="check"
            />
          </div>
          <div
            v-else
            class="button no-sub"
            @click="tryFollow"
          >
            Suivre ce Waper
          </div>
        </div>
        <div
          v-if="menuLayout"
          class="logout"
        >
          <div class="hr" />
          <span
            class="link"
            @click="logout"
            >Me déconnecter</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'
import { badgeModal, checkLoginModal } from '~/modals/checkLogin'
import { updateProfileModal } from '~/modals/updateProfile'
import type { FormattedWaper } from '~/types'

const props = withDefaults(
  defineProps<{
    menuLayout?: boolean
    profil?: FormattedWaper
  }>(),
  {
    menuLayout: false,
    profil: undefined,
  },
)

const referentialStore = useReferentialStore()

const { isMobile, isLargeMobile, isTabletPortrait, isSmallScreen } =
  useCustomMediaQuery()

const { authenticated } = useAuthenticated()
const wapersStore = useWapersStore()

const tryFollow = async () => {
  if (authenticated.value) {
    await wapersStore.followWaper()
  } else {
    await checkLoginModal.open()
  }
}

const tryUnfollow = async () => {
  if (authenticated.value) {
    await wapersStore.unfollowWaper()
  }
}

const { user } = useTempAuth()
const infoUser = computed(() => {
  return props.profil ?? user.value
})

const badgeKeys = computed(() => {
  return Object.keys(infoUser.value?.badges_total ?? {}) as [
    'gold',
    'silver',
    'bronze',
  ]
})

const progressRank = computed(() => {
  const pointsTotal = infoUser.value?.points_total ?? 0
  switch (infoUser.value?.rank_name) {
    case 'Newbie':
      return (pointsTotal / 999) * 100
    case 'Casual':
      return ((pointsTotal - 1000) / (2499 - 1000)) * 100
    case 'Expert':
      return ((pointsTotal - 2500) / (7499 - 2500)) * 100
    case 'Master':
      return ((pointsTotal - 7500) / (99999 - 7500)) * 100
    case 'God of WAP':
      return 100
    default:
      return 0
  }
})

const { gtag } = useGtag()

const openProfile = () => {
  if (infoUser.value?.profile_completion_percent ?? 0 < 100) {
    gtag('event', 'cta', {
      cta_name: 'Completer mon profil',
    })
  } else {
    gtag('event', 'cta', {
      cta_name: 'Modifier mes informations',
    })
  }
  updateProfileModal.open()
}

const openBadgeSelector = () => {
  gtag('event', 'cta', {
    cta_name: 'Changer de badge',
  })
  badgeModal.open()
}

const vfm = useVfm()

const logout = async () => {
  useTempAuth().logout()
  vfm.closeAll()
}
</script>

<style lang="scss" scoped>
.account-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 5rem 2rem;

  @media (min-width: 992px) {
    padding: 6rem 4rem 8rem;
  }

  @media (min-width: 1400px) {
    padding: 8rem 8rem 10rem;
  }

  .since {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .button-container {
    margin-top: 2rem;

    .button {
      display: inline-block;
      height: 4.6rem;
      padding: 0 2rem;
      color: $white;
      line-height: 4.6rem;
      cursor: pointer;

      &.no-sub {
        background: $blue;
      }

      &.sub {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: #999;

        img {
          width: 1.1rem;
          height: 1rem;
          margin-left: 1rem;
        }
      }
    }
  }

  .hr {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, $white, transparent);
    opacity: 0.3;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 71rem;
  }

  .first-part {
    display: flex;
    justify-content: space-between;

    @media (min-width: 992px) {
      align-items: center;
    }

    .profile {
      margin-right: 2rem;
      text-align: center;
    }

    .img-gauge-container {
      position: relative;
      width: 11rem;
      height: 11rem;
      background: rgba(#57595c, 0.23);
      border-radius: 50%;

      @media (min-width: 992px) {
        width: 23.4rem;
        height: 23.4rem;
      }

      .gauge-container {
        position: absolute;
        top: 0.7rem;
        left: 0.7rem;
        width: 9.6rem;
        height: 9.6rem;
        background: $white;
        border-radius: 50%;

        @media (min-width: 992px) {
          top: 1.5rem;
          left: 1.5rem;
          width: 20.4rem;
          height: 20.4rem;
        }
      }

      .img-container {
        position: absolute;
        top: 1.2rem;
        left: 1.2rem;
        width: 8.6rem;
        height: 8.6rem;
        overflow: hidden;
        border-radius: 50%;

        @media (min-width: 992px) {
          top: 2.4rem;
          left: 2.4rem;
          width: 18.6rem;
          height: 18.6rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .badge-container {
        position: absolute;
        top: -0.2rem;
        right: -0.2rem;
        width: 4.2rem;
        height: 4.2rem;
        overflow: hidden;
        border-radius: 50%;

        @media (min-width: 992px) {
          width: 9rem;
          height: 9rem;
        }

        &.gold {
          background-image: radial-gradient(circle at 75%, #ffdd67, #ffcb17);
          border: 2px solid #fcc402;
        }

        &.silver {
          background-image: radial-gradient(circle at 75%, #cecece, #a3a3a3);
          border: 2px solid #a5a5a5;
        }

        &.bronze {
          background-image: radial-gradient(circle at 75%, #f0aa53, #d88520);
          border: 2px solid #d38220;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: right center;
        }
      }
    }

    .button-change-badge {
      display: inline-block;
      margin-top: 0.6rem;
      color: $white;
      font-size: 1rem;
      line-height: 2.2rem;
      text-decoration: none;
      border-bottom: 1px solid $white;
      cursor: pointer;

      @media (min-width: 992px) {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 3rem;
      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      width: 38rem;

      @media (min-width: 1600px) {
        width: 43rem;
      }

      h3 {
        margin-top: 1.5rem;
        font-size: 2.4rem;
        font-weight: 300;
        line-height: 1.2;

        @media (min-width: 992px) {
          margin-top: 0;
          font-size: 3.2rem;
        }
      }

      .level {
        margin-top: 0.3rem;
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.16;

        @media (min-width: 992px) {
          margin-top: 0.5em;
          font-size: 1.4rem;
          line-height: 1.3;
        }
      }

      .points {
        font-family: 'SST Condensed';
        font-size: 2.8rem;
        font-weight: bold;
        line-height: calc(33 / 28);

        @media (min-width: 992px) {
          margin-top: 1.2rem;
          font-size: 4.2rem;
          line-height: 1.3;
        }
      }

      .medals {
        display: flex;
        margin-top: auto;

        @media (min-width: 992px) {
          margin-top: 1.2rem;
        }

        .medal {
          display: flex;
          align-items: center;
          margin-right: 2rem;

          @media (max-width: 700px) {
            &:last-child {
              margin-right: 0;
            }
          }

          .medal-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.4rem;
            height: 2.4rem;
            border-width: 0.3rem;
            border-style: solid;
            border-radius: 50%;

            @media (min-width: 992px) {
              width: 3.3rem;
              height: 3.3rem;
            }
          }

          &.gold {
            .medal-icon {
              background: #fed02f;
              border-color: #ffe99d;

              img {
                width: 1.3rem;
                height: 1.3rem;
              }
            }
          }

          &.silver {
            .medal-icon {
              background: #aaa;
              border-color: #cbcbcb;

              img {
                width: 0.854rem;
                height: 1.403rem;
              }
            }
          }

          &.bronze {
            .medal-icon {
              background: #d5831f;
              border-color: #f0ab56;

              img {
                width: 0.897rem;
                height: 0.897rem;
              }
            }
          }

          .count {
            margin-left: 0.6rem;
            font-family: 'SST Condensed';
            font-size: 1.1rem;
            font-weight: bold;

            @media (min-width: 992px) {
              margin-left: 1rem;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  .second-part {
    margin-top: 3rem;
    color: $white;

    @media (min-width: 992px) {
      align-self: flex-end;
      width: 38rem;
      margin-top: 0;
    }

    @media (min-width: 1600px) {
      width: 43rem;
    }

    .progress-gauge {
      margin: 2.5rem 0;

      @media (min-width: 992px) {
        margin-top: 2.7em;
        margin-bottom: 3.7rem;
      }

      .count {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }

      .gauge {
        margin-top: 1.7rem;
      }
    }
  }

  .logout {
    padding-top: 2.4rem;

    @media (min-width: 992px) {
      padding-top: 2.8rem;
    }
  }

  .link {
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
    cursor: pointer;

    @media (min-width: 992px) {
      margin-top: 2.8rem;
    }

    &::before {
      content: '';
      display: block;
      width: 0.5rem;
      height: 1rem;
      margin-right: 0.8rem;
      background-image: url('~/assets/icons/chevron.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      filter: brightness(0) invert(1);
    }
  }
}
</style>
