<template>
  <div>
    <p class="u-mb-4">Voulez-vous vraiment supprimer votre compte ?</p>
    <div class="u-d-f u-ai-c u-jc-sb">
      <ButtonSimple
        type="button"
        label="Valider la suppression"
        color="danger"
        @click="deleteAccount"
      />
      <ButtonSimple
        type="button"
        label="Annuler la demande"
        @click="vfm.closeAll()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'

const vfm = useVfm()

const auth = useAuth()

const socialStore = useSocialStore()

const deleteAccount = async () => {
  const { error } = await useCustomFetch(API.DELETE__USER, {
    method: 'DELETE',
  })

  if (error.value) {
    return console.error(error.value)
  }

  socialStore.initSocial()
  await auth.signOut({ callbackUrl: '/' })
  vfm.closeAll()
}
</script>
